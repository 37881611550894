/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Root font fallback variables */
:root {
  --font-fallback: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Base styles */
html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter', var(--font-fallback);
}

/* Font declarations */
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-VariableFont_opsz,wght.woff2') format('woff2') tech('variations');
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-variation-settings: 'opsz' 14;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Italic-VariableFont_opsz,wght.woff2') format('woff2') tech('variations');
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-variation-settings: 'opsz' 14;
}

/* Font loading states */
.fonts-failed body {
  font-family: var(--font-fallback);
}

/* Root styles */
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Add fade-in animation for text elements */
.heading-text, 
.body-text {
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards;
  animation-delay: 0.1s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Code styles */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Remove default list styles */
ul, ol {
  list-style: none;
}

/* Remove default button styles */
button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

/* Remove default anchor styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Ensure images don't overflow */
img {
  max-width: 100%;
  height: auto;
  display: block;
}
